var Headroom = (function() {
  function Headroom() {
    // Private Vars
    var $header, didScroll, lastScrollTop, delta, navbarHeight, scrlTimer;

    // Private Functions
    var init = function(el) {
      $header = $(el);
      didScroll = false;
      lastScrollTop = 0;
      delta = 1;
      navbarHeight = $header.outerHeight();
      scrlTimer = setInterval(debounceScroll, 250);
      addEvents();
    };

    var addEvents = function() {
      $(window).on("scroll", onScroll);
    };

    var onScroll = function() {
      didScroll = true;
    };

    var debounceScroll = function() {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    };

    var hasScrolled = function(e) {
      var st = $(this).scrollTop();
      if (Math.abs(lastScrollTop - st) <= delta) return;
      if (st > lastScrollTop && st > navbarHeight) {
        $header.removeClass("nav-down").addClass("nav-up");
      } else {
        if (st + $(window).height() < $(document).height()) {
          $header.removeClass("nav-up").addClass("nav-down");
        }
      }
      lastScrollTop = st;
    };

    // Public Functions
    this.init = function(el) {
      init(el);
    };

    this.destroy = function() {
      clearInterval(scrlTimer);
      $(window).off("scroll", onScroll);
    };
  }

  return Headroom;
})();
