var ContactForm = (function() {
  function ContactForm() {
    // Private Vars
    var $form, $msg, error;

    // Private Functions
    var init = function(el) {
      $form = $(el);
      msg = "";
      addEvents();
    };

    var addEvents = function() {
      $form.on("submit", onSubmit);
    };

    var onSubmit = function(e) {
      e.preventDefault();
      $.ajax({
        type: "POST",
        url: "api/form",
        data: $(this).serialize(),
        dataType: "json",
        success: function(result) {
          // form data successfully reached form processor api
          if (result.success) {
            // message successfully sent
            $(".form-result").text("Wir haben Ihre Nachricht erhalten! 😀");
            $("#form").addClass("hide");
          } else {
            // an issue was encountered
            if (
              result.errors == undefined ||
              result.errors == null ||
              result.errors.length == 0
            ) {
              // no validation errors - an email sending error was encountered
              $form.find(".form-result").text(result.msg);
            } else {
              // a validation error was encountered
              msg = "Achtung: <br>";
              if (result.errors.indexOf("name") != -1) {
                msg += "Bitte verraten Sie uns Ihren Namen.";
              }
              if (result.errors.indexOf("email") != -1) {
                msg += "Bitte verraten Sie uns Ihre Email.";
              }
              if (result.errors.indexOf("phone") != -1) {
                msg += "Bitte verraten Sie uns Ihre Telefonnummer.";
              }
              if (result.errors.indexOf("treatment") != -1) {
                msg += "Bitte wählen Sie eine Leistung.";
              }
              if (result.errors.indexOf("contact") != -1) {
                msg += "Wie sollen wir Sie kontaktieren?";
              }
              if (result.errors.indexOf("check") != -1) {
                msg += "Bitte setzen Sie einen hacken!";
              }
              if (result.errors.indexOf("website") != -1) {
                msg += "Sieht so aus als wären Sie ein Robot!?";
              }
              $form.find(".form-result").html(msg);
            }
          }
        },
        error: function(result) {
          // the form was unable to reach processor api
          $form
            .find(".form-result")
            .text(
              "Fehler " +
                result.status +
                " — Leider kann das Formular nicht bearbeitet werden: " +
                result.statusText
            );
        }
      });
    };

    // Public Functions
    this.init = function(el) {
      init(el);
    };
  }

  return ContactForm;
})();
