$(document).ready(function() {
  // responsive video embeds using fitvid.js
  $('iframe[src*="youtube"]')
    .parent()
    .fitVids();
  $('iframe[src*="vimeo"]')
    .parent()
    .fitVids();

  // handle contact form
  var contactForm = new ContactForm();
  contactForm.init("#form");

  // mobile navigation
  $(".burger, .overlay").click(function() {
    $(".burger").toggleClass("clicked");
    $(".overlay").toggleClass("show");
    $("nav").toggleClass("show");
    $("body").toggleClass("overflow mobile-nav");
  });
});

// utils > grid overlay > remove for production
var showGridOverlay = function(e) {
  var $gridOverlay = $("#gridoverlay");
  e.preventDefault;
  if (e.keyCode == 71 && e.ctrlKey == true) {
    $gridOverlay.toggleClass("show");
  }
};

$(document).on("keyup", showGridOverlay);
